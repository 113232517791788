<template>
  <div>
    <transition name="slide" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PageTransition',
  data() {
    return {
      forward: true,
    };
  },
  watch: {
    $route(to, from) {
      this.forward = to.meta.index > from.meta.index;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-leave-active {
  position: absolute;
}

.router-link-active + * {
  animation: slide-in 0.5s ease-out both;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>