<template>
  <div
    style="padding: 10px; border-radius: 10px; font-size: clamp(0.9rem, 2vw, 1.2rem)"
    :style="{ 'backgroundColor': popupMessage.cssBackgroundColour }"
    class="d-flex align-items-center justify-content-center mx-0 mx-md-3 showPopupHeight mb-0 black-text"
  >
    <i :class="popupMessage.FAiconCode" class="mr-3 h3 m-0" />
    {{ popupMessage.popupText }}
    <mdb-btn
      class="btn primary-btn btn-radius m-0 ml-3"
      style="font-size:0.8rem !important;padding: 0.5rem 1.6rem;border: black solid 2px;;"
      :style="{ 'background': `${popupMessage.cssBackgroundColour} !important` }"
      size="sm"
      @click="goToURL(popupMessage.buttonURL)"
    >
      {{ popupMessage.buttonText }}
    </mdb-btn>
  </div>
</template>

<script>
export default {
  name: 'PopupMessage',
  props: {
    popupMessage: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    goToURL (url) {
      this.$emit('show')
      this.$router.push(url).catch(() => {})
    }
  }
}
</script>
