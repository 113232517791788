<template>
  <mdb-modal
    v-if="!publicPage"
    id="orientationModal"
    class="usePortrait"
    style="background-color: black;"
  >
    <mdb-modal-body :class="{ 'modalHeight': mobileView }" style="background-color: black;">
      <div class="ml-0 ml-md-3 ml-lg-4 p-2" style="margin-top:0%;width:100%;">
        <img src="./../../assets/logo.png" style="width:70%;display: block;margin: 0px auto;padding: 10px;border-radius: 5px;">
      </div>
      <div class="primary-colour text-center" style="font-size:1rem;text-align:center;margin-top:10px;">
        Grassroots Lottery is best viewed in portait mode!
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrientationModal',
  data () {
    return {
      publicPage: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView'
    ])
  },
  created() {
    if (window.location.href.indexOf('public') > -1) {
      this.publicPage = true;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
