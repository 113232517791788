import 'bootstrap-css-only/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'mdbvue/lib/mdbvue.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import VueCardFormat from 'vue-credit-card-validation'
import Vue from 'vue'
import App from './App.vue'
import router from '../src/router'
import store from './store'
import Verte from 'verte'
import 'verte/dist/verte.css'
import * as rules from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { PaginationPlugin, BTable } from 'bootstrap-vue'
import '../mdb/mdbvue/scss/mdb-pro.scss'
import '../src/common/styles.css'
import * as mdbvue from 'mdbvue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueFilterDateFormat from 'vue-filter-date-format'
import DisableAutocomplete from 'vue-disable-autocomplete'

Vue.use(VueCardFormat)
Vue.use(VueAwesomeSwiper)
Vue.use(DisableAutocomplete)
Vue.use(PaginationPlugin)

for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('BTable', BTable)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('verte', Verte)

Vue.use(VueFilterDateFormat)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
