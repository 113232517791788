<template>
  <CardPreviewFromProps
    class="mr-3 my-2 houdiniBorder"
    :style="{'height': `${width * 2}px` , 'width': `${width}px`}"
    :hero="hero"
    :colour="colour"
    :sport="sport"
    :number="number"
    :first-initial="firstInitial"
    :second-initial="secondInitial"
    :houdini-border="true"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import CardPreviewFromProps from './cardPreviewFromProps.vue'

export default {
  name: 'CardPreviewFromStore',
  components: {
    CardPreviewFromProps
  },
  props: {
    height: {
      type: [String],
      default: function () {
        return ''
      }
    },
    width: {
      type: [String],
      default: function () {
        return ''
      }
    },
  },
  data () {
    return {
      isFirefox: false
    }
  },
  computed: {
    ...mapGetters(['step', 'hero', 'sport', 'colour', 'number', 'firstInitial', 'secondInitial']),
    picturePath () {
      try {
        if (this.sport !== 0) {
          return `/characterImages/${this.hero}.${this.readableColour}.${this.sport}.png`
        } else if (this.colour !== '#e1e1e1') {
          return `/characterImages/${this.hero}.${this.readableColour}.png`
        } else if (this.hero !== 0) {
          return `/characterImages/${this.hero}.png`
        }
        return '/0.png'
      } catch {
        return '/0.png'
      }
    },
    readableColour () {
      switch (this.colour) {
        case '#ff0000': // red
          return 1
        case '#0000ff': // blue
          return 2
        case '#ffff00': // yellow
          return 3
        case '#008000': // green
          return 4
        case '#ff9900': // orange
          return 5
        case '#800080': // purple
          return 6
        case '#fa42d2': // pink
          return 7
        case '#ffffff': // white
          return 8
        default :
          return 0
      }
    }
  },
  created () {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.isFirefox = true
    }
  }
}
</script>
