<template>
  <div>
    <!--generic confirm modal -->
    <mdb-modal
      id="genericConfirmModal"
      :show="showing"
      @close="close()"
    >
      <mdb-modal-header class="modal-custom-header">
        <mdb-modal-title>{{ confirmTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="modal-custom-body">
        <p class="my-4" style="border-top:1px solid white;margin-top:-10px !important;" />
        <p class="my-4" style="text-align:left;color:#1FFC35 !important;">
          {{ confirmMessage }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer class="modal-custom-footer">
        <div v-if="confirmButtonMessage2 != ''" class="d-flex align-items-center mr-auto">
          <mdb-btn
            v-if="!buttonConfirmed2"
            style="width:120px;margin:0 !important;font-size:0.8rem !important;"
            class="btn primary-btn btn-radius btn-sm btn-md"
            color="primary"
            @click.native="confirm2()"
          >
            <div style="margin-top:-5px;">
              {{ confirmButtonMessage2.toUpperCase() }} y
            </div>
          </mdb-btn>
          <mdb-btn
            v-else
            style="width:120px;margin:0 !important;font-size:0.8rem !important;"
            class="btn primary-btn btn-radius btn-sm btn-md"
            color="primary"
          >
            <i class="fa fa-spin fa-spinner" />
          </mdb-btn>
        </div>
        <div class="d-flex align-items-center">
          <mdb-btn
            v-if="!buttonConfirmed"
            style="width:120px;margin:0 !important;font-size:0.8rem !important;"
            class="btn primary-btn btn-radius btn-sm btn-md"
            color="primary"
            @click.native="confirm()"
          >
            <div style="margin-top:-1px;">
              {{ confirmButtonMessage.toUpperCase() }}
            </div>
          </mdb-btn>
          <mdb-btn
            v-else
            style="width:120px;margin:0 !important;font-size:0.8rem !important;"
            class="btn primary-btn btn-radius btn-sm btn-md"
            color="primary"
          >
            <i class="fa fa-spin fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GenericConfirmModal',
  components: {
  },
  data () {
    return {
      buttonConfirmed: false,
      buttonConfirmed2: false
    }
  },
  computed: {
    ...mapGetters(['genericConfirmModal']),
    confirmTitle () {
      if (this.genericConfirmModal.confirmTitle === '') {
        return 'Confirm'
      } else { return this.genericConfirmModal.confirmTitle }
    },
    confirmMessage () {
      if (this.genericConfirmModal.confirmMessage === '') {
        return 'Are you sure?'
      } else { return this.genericConfirmModal.confirmMessage }
    },
    confirmButtonMessage () {
      if (this.genericConfirmModal.confirmButtonMessage === '') {
        return 'CONTINUE'
      } else { return this.genericConfirmModal.confirmButtonMessage }
    },
    confirmButtonFunction () {
      return this.genericConfirmModal.confirmButtonFunction
    },
    confirmButtonMessage2 () {
      if (this.genericConfirmModal.confirmButtonMessage2 === '') {
        return ''
      } else { return this.genericConfirmModal.confirmButtonMessage2 }
    },
    confirmButtonFunction2 () {
      return this.genericConfirmModal.confirmButtonFunction2
    },
    showing () {
      return this.genericConfirmModal.showing
    }
  },
  watch: {
    showing () {
      if (this.showing) {
        this.buttonConfirmed = false
        this.buttonConfirmed2 = false
      }
    }
  },
  created () {
  },
  updated () {
  },
  methods: {
    close () {
      this.buttonConfirmed = false
      this.buttonConfirmed2 = false
      this.$store.commit('GENERIC_CONFIRM_MODAL', {
        showing: false,
        confirmTitle: '',
        confirmMessage: '',
        confirmButtonMessage: '',
        confirmButtonMessage2: '',
        confirmButtonFunction: () => { return 'emptyanon' },
        confirmButtonFunction2: () => { return 'emptyanon' }
      })
    },
    confirm () {
      this.buttonConfirmed = true
      this.confirmButtonFunction()
    },
    confirm2 () {
      this.buttonConfirmed2 = true
      this.confirmButtonFunction2()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
