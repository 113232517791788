<template>
  <div>
    <!--generic error modal -->
    <mdb-modal
      id="genericErrorModal"
      :show="showing"
      @close="close()"
    >
      <mdb-modal-header class="modal-custom-header">
        <mdb-modal-title>{{ errorTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="modal-custom-body">
        <p class="my-4" style="border-top:1px solid white;margin-top:-10px !important;" />
        <p
          class="my-4"
          style="text-align:left;color:#1FFC35 !important;"
          v-html="errorMessage"
        >
          {{ errorMessage }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer class="modal-custom-footer">
        <mdb-btn
          v-show="!spinningFlag"
          style="width:120px;margin:0 !important;font-size:0.8rem !important;"
          class="btn primary-btn btn-radius btn-sm btn-md"
          color="primary"
          @click.native="close()"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GenericErrorModal',
  components: {
  },
  data () {
    return {
      spinningFlag: false
    }
  },
  computed: {
    ...mapGetters(['genericErrorModal']),
    errorTitle () {
      if (this.genericErrorModal.errorTitle === '') {
        return 'Error'
      } else { return this.genericErrorModal.errorTitle }
    },
    errorMessage () {
      if (this.genericErrorModal.errorMessage === '') {
        return 'Sorry, something went wrong'
      } else { return this.genericErrorModal.errorMessage }
    },
    showing () {
      return this.genericErrorModal.showing
    }
  },
  watch: {
    showing () {
      if (this.showing) {
        this.$store.commit('CLOSE_GENERIC_CONFIRM_MODAL')
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('GENERIC_ERROR_MODAL', { showing: false, errorTitle: '', errorMessage: '' })
    }
  }
}
</script>
