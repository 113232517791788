<template>
  <div
    style="position:fixed;top:0;left:0;padding:35px;width:100dvw;background: var(--bg-color)"
    class="mobilePreview"
    :class="{'mobilePreviewShowingFade': mobilePreviewShowing, 'dvh': safari === false, 'noDvh': safari }"
  >
    <PerspectiveContainer>
      <div
        style="height:100%;"
        class="d-flex align-items-center justify-content-center"
        @click="hidePreview()"
      >
        <CardPreviewFromStore
          font-size="4rem"
          icon-font-size="5rem"
          width="300"
        />
        <mdb-btn
          class="btn primary-btn btn-right btn-radius btn-sm btn-md hidePreviewButton"
          @click="hidePreview()"
        >
          CLOSE
        </mdb-btn>
      </div>
    </PerspectiveContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardPreviewFromStore from '@/components/cardPreviews/cardPreviewFromStore.vue'
import PerspectiveContainer from '@/components/UI/perspectiveContainer.vue'

export default {
  name: 'FullScreenCardPreview',
  components: {
    PerspectiveContainer, CardPreviewFromStore
  },
  computed: {
    ...mapGetters([
      'mobilePreviewShowing',
      'safari'
    ])
  },
  methods: {
    hidePreview () {
      this.$store.commit('setMobilePreviewShowing', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobilePreview {
  z-index: -10;
  opacity:0;
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobilePreview.mobilePreviewShowingFade {
  z-index: 100000;
  opacity:1;
  transition: .5s ease;
}
.hidePreviewButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem !important;
  position: absolute;
  bottom: 30%;
  left: calc(50% - 50px);
  width: 100px;
  z-index: 10;
}
.hidePreviewButton i {
  margin: 3px 0 0 2px;
}
.dvh {
  height: 100dvh;
}
.noDvh {
  height: 100vh;
}
</style>
