<template>
  <div
    style="width: 100%;background:var(--bg-color);border-bottom:solid 1px #c0c0c0;position: relative; "
    class="d-flex bottom-shadow align-items-center justify-content-center navBarHeight"
  >
    <Stars
      style="position: absolute; top: 0; left: 0; height: 100%; width: 100%;z-index: 0"
      :star-count="100"
      image-path="stars2.png"
    />
    <div style="position: absolute; top: calc(50% - 17px); left: 20px;">
      <div
        v-if="currentBasket.length > 0"
        style="position: relative;"
        class="cp"
      >
        <Stars
          v-if="newBasketItem"
          style="height: 200%; width: 200%"
          :star-count="20"
          image-path="stars2.png"
        />
        <mdb-icon
          icon="shopping-basket"
          class="primary-colour"
          style="font-size:2rem"
          @click.native="goToBasket();"
        />
        <span
          class="basketCount"
          @click="goToBasket();"
        >{{ currentBasket.length }}</span>
      </div>
    </div>
    <img
      style="z-index: 1"
      class="main-logo d-none d-sm-block cp"
      src="@/assets/logo.png"
      @click="goToPlay()"
    >
    <img
      style="z-index: 1"
      class="mobile-logo d-sm-none ml-3 cp"
      src="@/assets/mobile-logo.png"
      @click="goToPlay()"
    >
    <div style="position: absolute; top: calc(50% - 17px); right: 20px;">
      <div
        class="primary-colour"
        style="cursor:pointer;box-shadow:none;"
      >
        <mdb-icon
          v-if="showBurgerMenu"
          icon="bars"
          size="2x"
          style="z-index:1050;font-size:2rem;"
          :class="{'d-md-none' : !routeNeedsMenu }"
          class="mobNavTop"
          @click.native="handleOpenBurgerMenu()"
        />
        <mdb-icon
          v-else
          icon="times"
          size="2x"
          style="z-index:1050;font-size:2rem;"
          class="mobNavTop"
          @click.native="handleCloseBurgerMenu()"
        />
      </div>
      <mdb-side-nav-2
        v-model="show"
        right
        :over="false"
        push
        style="background: var(--bg-color);"
      >
        <div slot="content" style="margin-top:5px;z-index:10000;position: relative;height: fit-content;min-height: calc(100vh + 180px);">
          <SideMenu
            @logout="logout()"
            @show="show = false"
          />
        </div>
      </mdb-side-nav-2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdbIcon, mdbSideNav2 } from 'mdbvue'
import SideMenu from './sideMenu.vue'
import Stars from '@/components/UI/stars.vue'

export default {
  name: 'NavBar',
  components: {
    mdbSideNav2,
    mdbIcon,
    SideMenu,
    Stars
  },
  data () {
    return {
      show: false,
      iOS: false,
      buttonClick: false,
      pin: '',
      newBasketItem: false,
      basketCount: 0
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'loginData',
      'rootData',
      'mobileView',
      'currentBasket'
    ]),
    routeNeedsMenu () {
      const routesNeedingMenu = ['/auth', 'basket/auth', '/basket/checkout', '/play', '/', '/home', '/play/checkout']
      return routesNeedingMenu.includes(this.$route.path)
    },
    showBurgerMenu () {
      return !this.show && this.$route.path !== '/play/auth'
    }
  },
  watch: {
    '$store.state.currentBasket' () {
      this.basketCount = this.currentBasket.length
    },
    basketCount (newVal, oldVal) {
      if (newVal > oldVal) {
        this.newBasketItem = true
        setTimeout(() => {
          this.newBasketItem = false
        } ,10000)
      }
    }
  },
  methods: {
    handleOpenBurgerMenu () {
      if (this.userData.aut) {
        this.openSideMenu()
      } else {
        this.login()
      }
    },
    handleCloseBurgerMenu () {
      this.show = false
      this.goToPlay()
    },
    openSideMenu () {
      this.show = !this.show
    },
    login () {
      this.$router.push({ path: '/play/auth', query: { page: 1 } }).catch(() => {})
      this.$store.commit('storePanelViewedOnMobile', 'Right')
    },
    goToPlay () {
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      this.$store.commit('storePanelViewedOnMobile', 'Left')
    },
    goToBasket () {
      if (this.userData.aut) {
        this.$router.push('/basket/checkout').catch(() => {})
      } else {
        this.$router.push('/basket/auth').catch(() => {})
      }
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.show = false
    },
    async logout () {
      this.show = false
      this.username = ''
      this.password = ''
    }
  }
}
</script>

<style>
.basketCount {
  position:absolute;
  color: var(--bg-color);
  background: var(--pr-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  right:-23px;
  top: 4px;
  font-size: 0.85rem;
}
  .navBarHeight {
    height: var(--desktop-nav-height);
  }
  @media (max-width:576px) {
    .navBarHeight {
      height: var(--mobile-nav-height);
    }
  }
</style>
