<template>
  <div
    id="star"
    ref="star"
    style="height: 100%; width: 100%; overflow: hidden"
  >
    <img
      v-for="(num, i) in starCount"
      :key="i"
      class="character glitter-star"
      :style="{'top': `${myRand()}%`, 'left': `${myRand()}%`, 'animation-delay': Math.random() + 's'}"
      :src="require(`@/assets/${imagePath}`)"
    >
  </div>
</template>

<script>
export default {
  name: 'STARS',
  props: {
    imagePath: {
      type: String,
      required: true,
      default: ''
    },
    starCount: {
      type: Number,
      default: 10
    }
  },
  methods: {
    myRand () {
      let r = 50
      while (40 < r && r < 60) {
        r = Math.random() * 100
      }
      return r
    }
  }
}
</script>

<style scoped>
.character {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

img.glitter-star {
  position: absolute;
  height: 9px;
  width: 9px;

  animation: glitter 2s linear 0s infinite normal;
  -webkit-animation: glitter 2s linear 0s infinite normal;
  -moz-animation: glitter 2s linear 0s infinite normal;
  -ms-animation: glitter 2s linear 0s infinite normal;
  -o-animation: glitter 2s linear 0s infinite normal;
}

@keyframes glitter {
  0% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
}
</style>