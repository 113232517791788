<template>
  <div
    ref="perspectiveContainer"
    class="perspectiveContainer"
    @mouseleave="resetTransform"
    @mousemove="handleMouseMove"
  >
    <div
      ref="inner"
      class="inner"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'PerspectiveContainer',
  methods: {
    handleMouseMove (event) {
      const mydiv = this.$refs.inner
      const rect = mydiv.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const tiltX = (rect.height / 2 - mouseY) / 30;
      const tiltY = (mouseX - rect.width / 2) / 30;
      mydiv.style.transition = '.1s'
      mydiv.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;
    },
    resetTransform () {
      this.$refs.inner.style.transition = '1s'
      this.$nextTick(() => {
        this.$refs.inner.style.transform = 'rotateY(0deg) rotateX(0deg)'
      })
    }
  }
}
</script>

<style>
.perspectiveContainer {
  perspective: 800px;
}
.inner {
  position: relative;
  height: auto;
  width: auto;
}
</style>
